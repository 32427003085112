class ChartColor {
    constructor() {
        this.border_colors = ['rgba(52, 152, 219, 0.6)','rgba(46, 204, 113, 0.6)','rgba(241, 196, 15, 0.6)','rgba(231, 76, 60, 0.6)','rgba(155, 89, 182, 0.6)','rgba(149, 165, 166, 0.6)','rgba(41, 128, 185, 0.6)','rgba(26, 188, 156, 0.6)','rgba(230, 126, 34, 0.6)','rgba(192, 57, 43, 0.6)','rgba(142, 68, 173, 0.6)','rgba(127, 140, 141, 0.6)'];
        this.fill_colors = ['rgba(52, 152, 219, 0.5)','rgba(46, 204, 113, 0.5)','rgba(241, 196, 15, 0.5)','rgba(231, 76, 60, 0.5)','rgba(155, 89, 182, 0.5)','rgba(149, 165, 166, 0.5)','rgba(41, 128, 185, 0.5)','rgba(26, 188, 156, 0.5)','rgba(230, 126, 34, 0.5)','rgba(192, 57, 43, 0.5)','rgba(142, 68, 173, 0.5)','rgba(127, 140, 141, 0.5)'];
    }

    getFillColor(index) {
        return this.fill_colors[index];
    }

    getBorderColors(index) {
        return this.border_colors[index];
    }
}

export default ChartColor;