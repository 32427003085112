/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual surveys logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'toasts_session' %> to the appropriate
// layout file

/**
 * Save the toastr data for after reload. This is useful if we want to update
 * information on the page and show it immediately to the user without requiring
 * a manual reload while also showing the toast of the action.
 *
 * @param {string} sessionKey the key storing the toastr data
 * @param {object} toast the toastr data
 */
function saveToastForReload(sessionKey, toast) {
  const session_toastrs_messages = sessionStorage.getItem(sessionKey);
  if (session_toastrs_messages) {
    sessionStorage.setItem(sessionKey, JSON.stringify([...session_toastrs_messages, { status: toast.type, message: toast.msg }]));
  } else {
    sessionStorage.setItem(sessionKey, JSON.stringify([{ status: toast.type, message: toast.msg }]));
  }
}

/**
 * Display the stored toastr data according to the session key.
 * Will remove the data after use.
 *
 * @param {string} sessionKey the key storing the toastr data
 */
function showSavedSessionToasts(sessionKey) {
  const session_toastrs_messages = JSON.parse(sessionStorage.getItem(sessionKey));
  if (session_toastrs_messages && session_toastrs_messages.length > 0) {
    session_toastrs_messages.forEach(({ status, message }) => {
      toastr[status](message);
    });
  }
  sessionStorage.removeItem(sessionKey);
}

window.saveToastForReload = saveToastForReload;
window.showSavedSessionToasts = showSavedSessionToasts;